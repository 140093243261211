
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import InnerLoader from '../../../../../components/InnerLoader.vue';
import Table from '../../../../../components/Table/Table.vue';

export default defineComponent({
    name: 'product to warehouses',
    components: {
        InnerLoader,
        Table
    },
    props: {
        warehouses: Array
    },

    setup(props) {
        const emitter: any = inject('emitter');
        const loading = ref(false);
        const modal: any = ref();
        const warehouses_data = ref([]) as Record<any, any>;

        const columns = ref([
            {
                label: 'message.NO',
                key: 'no'
            },
            {
                label: 'message.WAREHOUSE_CODE',
                key: 'warehouse_code'
            },
            {
                label: 'message.STOCK',
                key: 'stock'
            }
        ]);
        // Emitter for open modal
        emitter.on('productToWarehouseAction', () => {
            modal.value = new Modal(document.getElementById('product_to_warehouses'));
            modal.value.show();
        });

        watchEffect(() => {
            // warehouses_data.value = props?.warehouses;
            if (props?.warehouses != null) {
                warehouses_data.value = props?.warehouses.filter(function(item: any) {
                    return item.stock != 0;
                });
            }
        });

        return {
            loading,
            warehouses_data,
            columns
        };
    }
});
